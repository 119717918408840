<template>
  <vue-recaptcha
    ref="recaptcha"
    :sitekey="site_key"
    :loadRecaptchaScript="true"
    @verify="onVerify"
    size="invisible"
    :see="site_key"
  />
</template>

<script>
import VueRecaptcha from "vue-recaptcha";
export default {
  props: ["submit"],
  data: () => ({
    site_key: process.env.VUE_APP_RECAPTCHA_KEY,
  }),
  methods: {
    onVerify(token) {
      this.submit(token);
      this.$refs.recaptcha.reset();
    },
    execute() {
      this.$refs.recaptcha.execute();
    },
  },
  components: { VueRecaptcha },
};
</script>

<style>
</style>