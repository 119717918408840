<template>
  <b-alert
    v-height-fade.appear
    variant="danger"
    class="alert-auto mb-2"
    :show="!!value"
    @input="$emit('input', null)"
    dismissible
  >
    <div class="alert-body">
      <feather-icon icon="InfoIcon" class="mr-50" />
      {{ value }}
    </div>
  </b-alert>
</template>

<script>
import { BAlert } from "bootstrap-vue";
import { heightFade } from "@core/directives/animations";
export default {
  components: { BAlert },
  props: {
    value: { type: String, default: null },
  },
  directives: { "height-fade": heightFade },
};
</script>
<style lang="scss" scoped>
.alert-auto {
  max-height: initial !important;
}
</style>